.gallery-container {
  /* max-width: 100%; */
  margin: auto;
}

.gallery-image {
  max-width: 100%;
  height: auto;
}

.gallery-thumbnail {
  max-width: 100%;
  height: auto;
}

.gallery-description {
  margin-top: 10px;
  font-style: italic;
  color: #555;
}
.image-size {
  height: 92vh;
  padding: 10px;

}
@media screen and (max-width: 860px) {
  .image-size {
    height: 86vh;
    padding: 10px;
  }
}
.image-gallery-custom-slide {
  max-width: 100%;
  max-height: 100%;
}
.image-container {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 200px;
  background-color: #f3f3f370;
}
.description{
  background-color: #f3f3f370;
  bottom: 50px;
  color: black;
  right:0px;
  line-height: 1;
  padding: 10px 20px;
  padding-right: 50px;
  position: absolute;
  white-space: normal;
  text-align: left;
  font-size: 20px;
}